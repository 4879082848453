<template>
  <exception-page type="404" />
</template>

<script>
import ExceptionPage from "./ExceptionPage";

export default {
  name: "e404",
  components: {
    ExceptionPage,
  },
};
</script>

<style scoped></style>
